body{
  background-color: #0b0b0b;
  color: white;
  text-align: center;
}
button{
  background-color: #00cdff;
}


input[type="checkbox"] {
  appearance: none;
   -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border: 2px solid #00cdff; /* Change to your desired color */
  border-radius: 4px; /* Adjust as needed */
  outline: none;
  cursor: pointer;
}

input[type="checkbox" i]:checked {
  background-color: #00cdff; 
  border-color: #00cdff; 
}
.blocTasks {
  margin-top: 50px;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;

}
.tasks .bgTasks{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.152);
  border-radius: 10px;
  padding: 10px;
}
.inputAdd,.inputSave{
  background-color: rgba(255, 255, 255, 0.152);
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.152) ;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
  font-size: 17px;
  padding: 3px 5px 0px;
}
.marginTB50{
  margin: 50px 0;

}
.active{
  background-color: transparent;
  color: white;
}
.btnAdd,.btnSave{
  height: 34px;
  border: 1px solid #00cdff ;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 10px 10px;

}
.edit,.delete{
  width: 25px;
  cursor: pointer;
  margin: 0 5px;
}
.descriptionTask{
  margin-right: 20px;
}
.category{
  height: 37px;
  border: 1px solid #00cdff ;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
  padding: 10px 20px;
}
.dFlexCenter{
  display: flex;
  align-items: center;
}
.dFlexCenter span{
  margin-left: 5px;
  padding-bottom: 3px;
}
